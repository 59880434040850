import { h } from "preact"
import { styled } from "goober"
import * as ContentModuleComponents from "../ContentModule"
import Image from "../Primitive/Image"

const UnderlayNorthTopOverlayWrap = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...theme.helpers.mh.x1,
  ...theme.helpers.mb.x1,
  '& > div': {
    position: 'relative',
    maxWidth: `${theme.breakpoints.xl}px`,
    ...theme.helpers.mh.auto,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .underlay-n-top-overlay-media-wrap': {
    position: 'relative',
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      position: 'static',
      order: 2
    }
  },
  '& .underlay-n-top-overlay-media': {
    '& img': {
      width: '100%'
    }
  },
  '& .overlay-brilliant-sound-anywhere-media-0': {
    position: 'absolute',
    top: 0,
    left: '55%',
    transform: 'translateX(-50%)',
    maxHeight: '178%',
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      maxHeight: '80%'
    }
  }
}))

const Content = styled('div')(({ theme }) => ({
  background: '#fec8a6',
  color: '#65303d',
  ...theme.helpers.ph.x1,
  ...theme.helpers.pb.x1,
  paddingTop: '48%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > h2': {
    ...theme.type.medium,
    color: '#65303d',
    ...theme.type.x3,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      width: '44.4444%',
      ...theme.type.x4,
    },
    '@media (min-width: 1280px)': {
      width: '36%',
      ...theme.type.x5
    }
  },
  '& .underlay-n-top-overlay-description': {
    ...theme.type.x2,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      width: '44.4444%'
    }
  },
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    ...theme.helpers.ph.x2,
    ...theme.helpers.pb.x2,
    ...theme.helpers.pt.x2,
    flexDirection: 'row'
  }
}))

export const UnderlayNorthTopOverlay = props => {
  return (
    <UnderlayNorthTopOverlayWrap id={props.id}>
      <div>
        <div className="underlay-n-top-overlay-media-wrap">
          {props.media ? <Image className={`underlay-n-top-overlay-media`} media={props.media} /> : null}
          {props.content.map((element, index) => {
            const ContentModule = ContentModuleComponents.getComponent(ContentModuleComponents, element.component)
            return <ContentModule key={index} {...element} className={`overlay-${element.id}-${index}`} />
          })}
        </div>
        <Content className="underlay-n-top-overlay-content">
          <h2>{props.title}</h2>
          <div className="underlay-n-top-overlay-description">{props.description}</div>
        </Content>
      </div>
    </UnderlayNorthTopOverlayWrap>
  );
};
