import { h } from "preact"
import { useState } from "preact/hooks"
import { styled } from "goober"
import RadioButton from "../Primitive/RadioButton"

const BesideNorthEastGridWrap = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr 340px auto',
  flexDirection: 'column',
  '& > h2': {
    ...theme.type.x3
  },
  '& .product-image': {
    position: 'relative',
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  '& .buying-controls-pricing': {
    display: 'flex',
    justifyContent: 'space-between',
    ...theme.type.x2,
    ...theme.type.bold
  }
}))

const MediaStyles = styled('img')`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 100%;
  opacity: ${({ active }) => active ? 1 : 0 };
  transition: opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

export const BesideNorthEastGrid = props => {
  const [color, setColor] = useState(props.variants[0]);

  return (
    <BesideNorthEastGridWrap className="beside-ne-grid">
      <h2>{props.title}</h2>
      <div>{props.description}</div>
      <div className="product-image">
      {props.media && props.media.file.url.length ?
        props.media.file.url.map((url, index) => {
          return <MediaStyles key={`media-${index}`} active={props.variants.indexOf(color) === index} src={url} alt="" />
        }) : null
      }
      </div>
      <div className="buying-controls-pricing">
        <form>
          <legend className="sr-only">Select color</legend>
          {props.variants.map((variant, index) => {
            return <RadioButton key={index} checked={color === variant} id={`pid-${props.id}-${variant}`} variant={variant} onChange={() => setColor(variant)}></RadioButton>
          })}
        </form>
        <div className="pricing">{props.price}</div>
      </div>
    </BesideNorthEastGridWrap>
  )
}
