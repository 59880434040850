import { h } from "preact"
import Image from "../Primitive/Image"

export const BasicMedia = ({ media, className }) => {
  return (
    <>
      {media ? <Image media={media} className={className} /> : null}
    </>
  )
}
