import { h } from "preact"

import Header from "./Header"

const Layout = ({children}) => {
  return (
    <>
      {<Header siteTitle={"sonos"} />}
      <main>
        {children}
      </main>
    </>
  )
}

export default Layout
