import { h } from "preact"
// import Img from "gatsby-image"
import { styled } from "goober"

const Picture = ({ className, media }) => {
  if (media.sources) {
    return (
      <picture className={className}>
        {media.sources && media.sources.map((source, index) => {
          return <source key={index} srcSet={source.srcSet} media={source.mq} />
        })}
        <img src={media.file.url} alt={media.title} />
      </picture>
    )
  }
  return <img className={className} src={media.file.url} alt={media.title} />
}

const Image = ({ className, media, imgStyles = {} }) => {
  if (media.file) {
    if (media.file.contentType === "image/svg+xml" && media.svgContent)
      return <span className={className} dangerouslySetInnerHTML={{ __html: media.svgContent }} />
    if (!(media.fixed || media.fluid) && media.file.url) return <Picture className={className} media={media} />
  }

  // return <Img className={className} fixed={media.fixed} fluid={media.fluid} imgStyle={imgStyles} />
}
const StyledImage = styled(Image)``

export default StyledImage
