import { createGlobalStyles } from "goober/global"

export const GLOBAL_ID = "container-sonos-page";
const globalClass = `.${GLOBAL_ID}`;

const GRID_COLUMNS = 27
const SPACINGS = [
  ["m", "margin"],
  ["p", "padding"],
]
const SPACING_MODIFIERS = [
  ["a", "top", "bottom", "left", "right"],
  ["v", "top", "bottom"],
  ["h", "left", "right"],
  ["t", "top"],
  ["r", "right"],
  ["b", "bottom"],
  ["l", "left"],
]

const breakpoints = {
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1440
}

const colors = {
  b1: "#000000",
  b1a: "#000000cc",
  b2: "#a6a8aB",
  b3: "#d8d8d8",
  b4: "#ffffff",
  b4a: "#ffffffcc",
  a1: "#1c5f4a",
  a2: "#bb4725",
  a3: "#d8a158",
  a4: "#f1d9d8",
  a5: "#bdd1e7",
  transparent: "transparent",
}

const spacing = {
  x0p25: `${(100 * 0.25) / GRID_COLUMNS}%`,
  x0p5: `${(100 * 0.5) / GRID_COLUMNS}%`,
  x0p75: `${(100 * 0.75) / GRID_COLUMNS}%`,
  x1: `${(100 * 1) / GRID_COLUMNS}%`,
  x1p5: `${(100 * 1.5) / GRID_COLUMNS}%`,
  x2: `${(100 * 2) / GRID_COLUMNS}%`,
  x2p5: `${(100 * 2.5) / GRID_COLUMNS}%`,
  x3: `${(100 * 3) / GRID_COLUMNS}%`,
  x4: `${(100 * 4) / GRID_COLUMNS}%`,
  x4p5: `${(100 * 4.5) / GRID_COLUMNS}%`,
  x5: `${(100 * 5) / GRID_COLUMNS}%`,
  x6: `${(100 * 6) / GRID_COLUMNS}%`,
  x7: `${(100 * 7) / GRID_COLUMNS}%`,
  x8: `${(100 * 8) / GRID_COLUMNS}%`,
  x9: `${(100 * 9) / GRID_COLUMNS}%`,
  x10: `${(100 * 10) / GRID_COLUMNS}%`,
  x11: `${(100 * 11) / GRID_COLUMNS}%`,
  x12: `${(100 * 12) / GRID_COLUMNS}%`,
  x13: `${(100 * 13) / GRID_COLUMNS}%`,
  x14: `${(100 * 14) / GRID_COLUMNS}%`,
  x15: `${(100 * 15) / GRID_COLUMNS}%`,
  x16: `${(100 * 16) / GRID_COLUMNS}%`,
  x17: `${(100 * 17) / GRID_COLUMNS}%`,
  x18: `${(100 * 18) / GRID_COLUMNS}%`,
  x19: `${(100 * 19) / GRID_COLUMNS}%`,
  x20: `${(100 * 20) / GRID_COLUMNS}%`,
  x21: `${(100 * 21) / GRID_COLUMNS}%`,
  x22: `${(100 * 22) / GRID_COLUMNS}%`,
  x23: `${(100 * 23) / GRID_COLUMNS}%`,
  x24: `${(100 * 24) / GRID_COLUMNS}%`,
  x25: `${(100 * 25) / GRID_COLUMNS}%`,
  x26: `${(100 * 26) / GRID_COLUMNS}%`,
  x27: `${(100 * 27) / GRID_COLUMNS}%`,
  nil: 0,
  auto: "auto",
}

const type = {
  x1: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  x1p5: {
    fontSize: '1.25rem',
    lineHeight: 1.416665,
  },
  x2: {
    fontSize: '1.5rem',
    lineHeight: 1.333333333333333,
    letterSpacing: '0.3px',
  },
  x2p5: {
    fontSize: `${1.25 ** 2}rem`,
    lineHeight: 1.32305,
  },
  x3: {
    fontSize: `${1.5 ** 2}rem`,
    lineHeight: 1.3125,
    letterSpacing: '-.1px',
  },
  x4: {
    fontSize: `${1.5 ** 3}rem`,
    lineHeight: 1.208333333333333,
    letterSpacing: 0,
  },
  x5: {
    fontSize: `${1.5 ** 4}rem`,
    lineHeight: 1.166666666666667,
    letterSpacing: '-2.7px',
  },
  x6: {
    fontSize: `${1.5 ** 5}rem`,
    lineHeight: 1.018518518518519,
  },
  x7: {
    fontSize: `${1.5 ** 6}rem`,
    lineHeight: 1,
  },
  light: {
    fontWeight: 300,
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
}

const helpers = {}

SPACINGS.forEach(([sProp, sCssProp]) => {
  SPACING_MODIFIERS.forEach(([smProp, ...smCssProps]) => {
    helpers[`${sProp}${smProp}`] = {}
    Object.keys(spacing).forEach(v => {
      helpers[`${sProp}${smProp}`][v] = 
        smCssProps.reduce((a, i) => ({ ...a, [sCssProp + i[0].toUpperCase() + i.slice(1)]: spacing[v] }), {})
    })
  })
})

const theme = {
  colors,
  fontFamily: "aktiv-grotesk,Helvetica,Arial,sans-serif",
  spacing,
  type,
  breakpoints,
  helpers,
}

export const GlobalStyles = createGlobalStyles`
  ${globalClass} { 
    font-family: ${theme.fontFamily};
    font-weight: normal;
    scroll-behavior: smooth;
    color: ${theme.colors.b1a};
    margin: 0 auto;
    background-color: ${theme.colors.b4};
    text-rendering: optimizeSpeed;
  }

  ${globalClass} h1,
  ${globalClass} h2,
  ${globalClass} h3,
  ${globalClass} h4,
  ${globalClass} h5,
  ${globalClass} h6 {
    font-family: ${theme.fontFamily};
    color: ${theme.colors.b1a};
    text-transform: none;
  }

  ${globalClass} * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  ${globalClass} ul[class],
  ${globalClass} ol[class] {
    list-style: none;
  }

  ${globalClass} a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  ${globalClass} a {
    text-decoration: none;
    color: inherit;
    border-color: inherit;
  }

  ${globalClass} img {
    max-width: 100%;
    display: block;
  }

  ${globalClass} input,
  ${globalClass} button,
  ${globalClass} textarea,
  ${globalClass} select {
    font: inherit;
    border: none;
    background: none;
  }

  ${globalClass} .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border:0;
  }

  @media (prefers-reduced-motion: reduce) {
    ${globalClass} * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`

export default theme
