const getContentById = (id, content) => content.find(element => element.id === id)

const getProductById = (id, products) => products.find(element => element.id === id)

const hydrateContent = (element, content, products) => {
  const module = getContentById(element, content)
  if (module && module.content) {
    module.content = module.content.map(component => getContentById(component, content))
  }
  if (module && module.products) {
    module.products = module.products.map(product => getProductById(product, products))
  }

  return module
}

export const getData = (langs, content, products, lang) => {
  const page = langs.find(element => element.lang === lang)
  const modules = page.modules.map(module => hydrateContent(module, content, products))

  return { modules }
}
