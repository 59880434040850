import { h } from "preact"
import { styled } from "goober";

const logoSvg = `<svg xmlns="http://www.w3.org/2000/svg" id="q2FoVbfDyAEFay8iKDX4JE__nrk9vmk8v1pMBgEue1bbJf__Layer_1" x="0" y="0" aria-hidden="true" version="1.1" viewBox="0 0 40 40" xml:space="preserve" focusable="false"><path fill-rule="evenodd" d="M21.7 20.5L17 16.1v7.4h1.4v-4.1l4.7 4.4v-7.4h-1.4v4.1zM10.8 16c-2.2 0-3.9 1.7-3.9 3.9 0 2.1 1.8 3.9 3.9 3.9 2.2 0 3.9-1.7 3.9-3.9S13 16 10.8 16m0 6.4c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5m-6.3-2.5c-.4-.3-.9-.5-1.6-.7-1.4-.4-1.4-.8-1.4-1 0-.4.5-.8 1.2-.8.6 0 1.1.3 1.3.4l.1.1 1.1-.8-.1-.1s-.9-1-2.4-1c-.7 0-1.4.2-1.9.6-.5.4-.8 1-.8 1.6 0 .6.3 1.2.8 1.6.4.3.9.5 1.6.7 1.4.4 1.4.8 1.4 1 0 .4-.5.8-1.2.8-.6 0-1.1-.3-1.3-.4l-.1-.1-1.1.8.1.1s.9 1 2.4 1c.7 0 1.4-.2 1.9-.6.5-.4.8-1 .8-1.6 0-.6-.3-1.1-.8-1.6M29.3 16c-2.2 0-3.9 1.7-3.9 3.9s1.8 3.9 3.9 3.9c2.2 0 3.9-1.7 3.9-3.9S31.4 16 29.3 16m0 6.4c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.2 2.5-2.5 2.5m6.3-2.6c.4.3.9.5 1.6.7 1.4.4 1.4.8 1.4 1 0 .4-.5.8-1.2.8-.6 0-1.1-.3-1.3-.4H36l-1.1.8.1.1s.9 1 2.4 1c.7 0 1.4-.2 1.9-.6.5-.4.8-1 .8-1.6 0-.6-.3-1.2-.8-1.6-.4-.3-.9-.5-1.6-.7-1.4-.4-1.4-.8-1.4-1 0-.4.5-.8 1.2-.8.6 0 1.1.3 1.3.4l.1.1 1.1-.8-.1-.1s-.9-1-2.4-1c-.7 0-1.4.2-1.9.6-.5.4-.8 1-.8 1.6-.1.6.2 1.1.8 1.5" clip-rule="evenodd"></path></svg>`
const HeaderStyled = styled('header')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > div': {
    maxWidth: `${theme.breakpoints.xl}px`,
    ...theme.helpers.mh.auto
  },
  '& svg': {
    display: 'block',
    width: '4.5rem'
  }
}))

const Header = ({ siteTitle }) => {
  return (
    <HeaderStyled>
      <div>
        <h1>
          <a
            href="https://www.sonos.com"
            aria-label={siteTitle}
            dangerouslySetInnerHTML={{ __html: logoSvg}}
          >
          </a>
        </h1>
      </div>
    </HeaderStyled>
  )
}

export default Header
