import { h, hydrate } from "preact"

import { setup } from "goober"

import theme, { GlobalStyles }  from "../utils/theme"

import Layout from "./Layout"
import { GLOBAL_ID } from "../utils/theme"

import * as ContentModuleComponents from "./ContentModule"

setup(h, undefined, () => theme)

const Body = (props) => {
  return (
    <>
      <Layout>
        <GlobalStyles />
        {props.modules.map((module, index) => {
          const ContentModule = ContentModuleComponents.getComponent(ContentModuleComponents, module.component)
          return <ContentModule key={index} {...module} />
        })}
      </Layout>
    </>
  )
}

const Widget = (props) => {
  const typekitUrl = "https://use.typekit.net/znf0vfz.css"
  const typekit = document.createElement("link")
  typekit.setAttribute("rel", "stylesheet")
  typekit.setAttribute("href", typekitUrl)
  document.getElementsByTagName("head")[0].appendChild(typekit)

  document.addEventListener('DOMContentLoaded', (event) => {
    const wrapper = document.querySelector(`#${GLOBAL_ID}`)
    wrapper && wrapper.classList.add(GLOBAL_ID)
    hydrate(
      <Body {...props} />,
      wrapper
    )
  })
}

export default Widget
