import { h } from "preact"
import { styled } from "goober"
import * as ProductTiles from "../ProductTile"

const ProductRowWrap = styled('section')(({ theme }) => ({
  ...theme.helpers.mb.x1,
  ...theme.helpers.mh.x1,
}))

const ProductRowContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridColumnGap: '2rem',
  gridRowGap: '1rem',
  maxWidth: `${theme.breakpoints.xl}px`,
  ...theme.helpers.mh.auto,
  width: '100%',
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))'
  }
}))

const Content = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  '& > h2': {
    ...theme.type.medium,
    ...theme.type.x3,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      ...theme.type.x5
    }
  },
  '& .product-row-description': {
    ...theme.type.x2,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      width: '56%'
    }
  }
}))

export const ProductRow = props => {
  return (
    <ProductRowWrap id={props.id}>
      <ProductRowContainer>
        <Content className="product-row-content">
          <h2>{props.title}</h2>
          <div className="product-row-description">{props.description}</div>
        </Content>
        {props.products.map((product, index) => {
          const ProductTile = ProductTiles.getComponent(ProductTiles, product.component)
          return <ProductTile key={index} {...product} component="BesideNorthEastGrid" />
        })}
      </ProductRowContainer>
    </ProductRowWrap>
  );
};
