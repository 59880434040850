import { h } from "preact"
import { styled } from "goober"

const headlineSize = "x3"
const mobileHeadlineSize = "x3"
const Headline = styled('h2')(({ theme }) => ({
  ...theme.type.medium,
  ...theme.type[mobileHeadlineSize],
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    ...theme.type[headlineSize]
  }
}))

export const BasicContentBlock = ({ title, description }) => {
  return (
    <>
      {title ? <Headline >{title}</Headline> : null}
      {description ? <p>{description}</p> : null}
    </>
  )
}
