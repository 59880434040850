import { h } from "preact"
import { styled } from "goober"

const RadioButton = ({ className="radio-button", onChange, id, variant="black", checked=false, children }) => (
  <>
    <label className={className + " " + variant} htmlFor={id}>
      <input type="radio" className="sr-only" id={id} name="pid" value={variant} checked={checked} onChange={onChange} />
      <span>{children}</span>
    </label>
  </>
)

const StyledRadioButton = styled(RadioButton)`
  display: inline-block;
  margin-left: 0.25rem;

  &:first-of-type {
    margin-left: 0;
  }

  & span {
    position: relative;
    height: 32px;
    width: 32px;
    display: block;
  }

  & span::before {
    content: "";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,.27);
  }

  &.black span::before { background-color: #000; }
  &.white span::before { background-color: #fff; }
  &.soft-white span::before { background-color: #eee; }
  &.shadow-black span::before { background-color: #464749; }
  &.lunar-white span::before { background-color: #cdced0; }
  &.olive span::before { background-color: #7C8476; }
  &.sunset span::before { background-color: #D07464; }
  &.wave span::before { background-color: #859CAB; }

  & span::after {
    content: "";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 32px;
    width: 32px;
    box-shadow: 0 0 0 1px ${props => props.theme.colors.bl};
    border-radius: 50%;
    transform: scale3d(.7,.7,.7);
    opacity: 0;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 500ms;
  }

  & span:hover {
    cursor: pointer;
  }

  input[type="radio"]:focus + span::after {
    box-shadow: 0 0 4px 1px ${props => props.theme.colors.bla};
  }

  input[type="radio"]:checked + span::after {
    transform: scaleX(1);
    opacity: 1;
  }
`

export default StyledRadioButton
