import { h } from "preact"
import { styled } from "goober"
import * as ContentModuleComponents from "../ContentModule"
import Image from "../Primitive/Image"

const SystemBenefitsWrap = styled('section')(({ theme }) => ({
  backgroundColor: theme.colors.a4,
  '& > div': {
    position: 'relative',
    maxWidth: `${theme.breakpoints.xl}px`,
    ...theme.helpers.mh.auto,
    ...theme.helpers.pt.x1
  }
}))

const OverlayContent = styled('div')(({ theme }) => ({
  '& > h2': {
    ...theme.type.medium,
    ...theme.type.x4,
    ...theme.helpers.mh.x1,
    ...theme.helpers.mb.x1,
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      ...theme.type.x5
    }
  },
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    /*width: 40.7407%;*/
  }
}))

const MediaContentWrap = styled('div')(({ theme }) => ({
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    display: 'flex',
    jusitfyContent: 'space-between'
  }
}))

const MediaWrap = styled('div')(({ theme }) => ({
  display: 'block',
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    order: 1,
    maxWidth: '59.2593%'
  }
}))

const MediaContent = styled('div')(({ theme }) => ({
  ...theme.helpers.mh.x1,
  ...theme.helpers.mv.x1,
  '& > h2': {
    ...theme.helpers.mt.x1,
    ...theme.helpers.mb.x0p25,
  },
  '& > h2:first-of-type': {
    ...theme.helpers.mt.nil,
  },
  [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
    ...theme.helpers.mv.nil
  }
}))

export const SystemBenefits = props => {
  return (
    <SystemBenefitsWrap id={props.id}>
      <div>
        <OverlayContent className="system-benefits-overlay-content">
          <h2>{props.title}</h2>
        </OverlayContent>

        <MediaContentWrap>
          <MediaWrap>
            {props.media ? <Image media={props.media} /> : null}
          </MediaWrap>
          <MediaContent>
            {props.content.map((element, index) => {
              const ContentModule = ContentModuleComponents.getComponent(ContentModuleComponents, element.component)
              return <ContentModule key={index} {...element} />
            })}
          </MediaContent>
        </MediaContentWrap>
      </div>
    </SystemBenefitsWrap>
  )
}
