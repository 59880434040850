import Widget from "./components/Widget";
import { getData } from "./data"

import langs from "./data/index.json"
import content from "./data/content-en-ca.json"
import products from "./data/products-en-ca.json"

const lang = "en-ca"
const props = getData(langs, content, products, lang)

Widget(props)
