import { h } from "preact"
import { styled } from "goober";
import Image from "../Primitive/Image"

const PrimaryBenefitWrap = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...theme.helpers.mb.x1,
  '& > div': {
    position: 'relative',
    maxWidth: `${theme.breakpoints.xl}px`,
    ...theme.helpers.mh.auto
  }
}));

const OverlayContent = styled('div')(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.3)',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.b4,
  ...theme.helpers.pa.x0p5,
  '& > h2': {
    ...theme.type.medium,
    ...theme.type.x3,
    color: theme.colors.b4,
    textAlign: 'center',
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      ...theme.type.x5
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      ...theme.type.x6
    }
  }
}))

export const PrimaryBenefit = props => {
  return (
    <PrimaryBenefitWrap id={props.id}>
      <div>
        {props.media ? <Image media={props.media} /> : null}
        <OverlayContent className="primary-benefit-overlay-content">
          <h2>{props.title}</h2>
        </OverlayContent>
      </div>
    </PrimaryBenefitWrap>
  );
};
